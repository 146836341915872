<template>
  <div class='icon-close' :style="`--color: ${color};`" />
</template>

<script>
export default {
  name: '',
  props: {
    color: {
      type: String,
      required: false,
      default: '#000',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
$iconSize: 25px;

.icon-close {
  position: relative;
  width: $iconSize;
  height: $iconSize;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: calc(#{$iconSize} / 2);
    height: $iconSize;
    width: 2px;
    background-color: var(--color);
    border-radius: $globalBorderRadius;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

span {
  display: inline-block;
  width: 20px;
  height: 3px;
  border-radius: $globalBorderRadius;
}
</style>
